import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { headerMaps, maps, setConf } from './conf'

const Reminder = ({ match }: any) => {
  const urlArr = match.url.split('/')
  const len = urlArr.length
  let temArr = ['']
  const home = '首页'
  const names = [...maps, ...headerMaps, ...setConf]?.map((item: any) => ({
    label: item.name,
    value: item.key,
  }))

  return (
    <>
      <Breadcrumb style={{ margin: 16 }}>
        {urlArr[0] === urlArr[1] && <Breadcrumb.Item>{home}</Breadcrumb.Item>}
        {urlArr[0] !== urlArr[1] &&
          urlArr?.map((item: any, index: any) => {
            let key = item !== '' ? item : 'home'

            if (item !== '') {
              temArr.push(item)
            }
            if (index !== len - 1) {
              return (
                <Breadcrumb.Item key={key}>
                  <Link to={temArr.join('/')}>
                    {item !== ''
                      ? names?.find((citem: any) => citem?.value === item)
                          ?.label
                      : home}
                  </Link>
                </Breadcrumb.Item>
              )
            } else {
              return (
                <Breadcrumb.Item key={key}>
                  {names?.find((citem: any) => citem?.value === item)?.label ||
                    item}
                </Breadcrumb.Item>
              )
            }
          })}
      </Breadcrumb>
    </>
  )
}

Reminder.propTypes = {
  match: PropTypes.object,
}

export default Reminder
