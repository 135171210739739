import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Badge } from 'antd'
import { Link } from 'react-router-dom'
import { maps } from './conf'
import { IRouterConf } from './interface'

const { Item, SubMenu } = Menu

const SiderMenus = ({ match }: any) => {
  // 获取匹配链接
  // const url_key = match.url?.split('/')

  const getMenuItems = (conf: IRouterConf[]) => {
    return conf?.map((params, index) => {
      if (params?.children && params?.children.length) {
        return (
          <>
            <SubMenu
              key={`sub_${params?.key}_${index}`}
              title={
                <span>
                  {params?.iconType}
                  <span>{params?.title}</span>
                </span>
              }
            >
              {getMenuItems(params?.children)}
            </SubMenu>
          </>
        )
      }

      // console.log('match:', match.url.split('/'), url_key[url_key.length - 1], maps, maps?.find(item => item?.key === url_key[url_key.length - 1]))
      

      return (
        <>
          <Item key={`item_${params?.key}_${index}`}>
            <Badge dot={params?.badge}>
              <Link to={params?.path} key={`link_${index}`}>
                {params?.iconType}
                <span>{params?.title}</span>
              </Link>
            </Badge>
          </Item>
        </>
      )
    })
  }

  return (
    <>
      <div
        style={{
          padding: 0,
        }}
      >
        <Menu
          theme="dark"
          defaultSelectedKeys={[match.url]}
          selectedKeys={[match.url]}
          // defaultOpenKeys={[maps?.find(item => item?.key === url_key[url_key.length - 1])?.key as string]}
          mode="inline"
          style={{
            overflowY: 'scroll',
            height: 760,
          }}
        >
          {getMenuItems(maps)}
        </Menu>
      </div>
    </>
  )
}

SiderMenus.propTypes = {
  match: PropTypes.object.isRequired,
}

export default SiderMenus
