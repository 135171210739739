import React, { Component } from 'react'
import { Layout } from 'antd'
import './App.css'
import {
  ContentRoutes,
  ReminderMenu,
  SiderMenusRoute,
} from './router/RouterMap'
import MenuHeader from './router/MenuHeader'
import { WebSite } from './router/conf'

const { Footer, Content, Sider } = Layout

class App extends Component {
  state = {
    collapsed: false,
  }

  onCollapse = (collapsed: any) => {
    this.setState({
      collapsed,
    })
  }

  render() {
    return (
      <>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <MenuHeader />
          <Layout style={{ paddingTop: '64px', overflowY: 'scroll' }}>
            <Sider
              width={200}
              style={{ background: '#333'}}
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              className="fixed"
              trigger={null}
            >
              <SiderMenusRoute />
            </Sider>
            <Layout
              className={
                this.state.collapsed ? 'content-normal' : 'content-max'
              }
            >
              <ReminderMenu />
              <Content
                style={{
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <ContentRoutes />
              </Content>
              <Footer
                style={{
                  textAlign: 'center',
                }}
              >
                {WebSite?.copyright}
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </>
    )
  }
}

export default App
