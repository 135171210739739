import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { maps } from './conf'
import SiderMenus from './SideMenus'
import NotFound from '../pages/error'
import Reminder from './Reminder'

export const SiderMenusRoute = () => <Route path="*" component={SiderMenus} />

export const ReminderMenu = () => <Route path="*" component={Reminder} />

export const ContentRoutes = () => {
  // 动态加载
  const lazyRoute = (Component: any) => {
    // console.log('Component:', Component);
    
    return (props: any) => <Component {...props} />
  }

  // bfs+dfs
  const queryLinks = (params: any): any => {
    if (Array.isArray(params) && params.length) {
      const flat = params?.map(item => {
        if (Array.isArray(item.children) && item.children.length) {
          return queryLinks(item.children)
        }

        return item
      })

      return flat
    }
    // const link = maps?.filter(item => !item?.children.length)

    return params
  }

  // 打平数组
  const queryFlatedArr = (params: any): any => params?.reduce((prev: any, cur:any) => {
      return prev.concat(Array.isArray(cur) ? queryFlatedArr(cur) : cur)
  }, [])
  

  return (
    <>
      <Switch>
        <Suspense fallback={<div>Loading</div>}>
          {queryFlatedArr(queryLinks(maps))?.map((item: any, index: any) => (
            <Route
              key={`route_${index}`}
              exact
              path={item?.path}
              component={lazyRoute(item?.component)}
            />
          ))}
        </Suspense>
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default ContentRoutes
