import React from 'react'
import { Layout, Menu, Badge, Avatar } from 'antd'
import { IHeaderConf, ISettingConf } from './interface'
import { Link } from 'react-router-dom'
import { headerMaps, setConf, WebSite } from './conf'
import { NotificationTwoTone, UserOutlined } from '@ant-design/icons'

const { SubMenu, Item } = Menu
const { Header } = Layout

const MenuHeader = () => {
  // 获取头部菜单
  const getHeadersValue = (conf: IHeaderConf[]) => {
    return conf?.map((params, index) => {
      if (params?.children && params?.children.length) {
        return (
          <>
            <SubMenu
              key={`header_sub_${params?.key}_${index}`}
              title={
                <span>
                  {params?.iconType}
                  <span>{params?.title}</span>
                </span>
              }
            >
              {getHeadersValue(params?.children)}
            </SubMenu>
          </>
        )
      }

      return (
        <>
          <Item key={`header_item_${params?.key}_${index}`}>
            <Badge dot={params?.badge}>
              <Link to={params?.path} key={`header_link_${index}`}>
                {params?.iconType}
                <span>{params?.title}</span>
              </Link>
            </Badge>
          </Item>
        </>
      )
    })
  }

  // 获取设置菜单
  const getSettings = (conf: ISettingConf[]) => {
    return conf?.map((params, index) => {
      if (params?.children && params?.children.length) {
        return (
          <>
            <Menu.ItemGroup
              key={`header_sub_${params?.key}`}
              title={
                <span>
                  {params?.iconType}
                  <span>{params?.title}</span>
                </span>
              }
            >
              {getHeadersValue(params?.children)}
            </Menu.ItemGroup>
          </>
        )
      }

      return (
        <>
          <Item key={`header_item_${params?.key} ${index}`}>
            <Badge dot={params?.badge}>
              <Link to={params?.path} key={`header_link_${index}`}>
                {params?.iconType}
                <span>{params?.title}</span>
              </Link>
            </Badge>
          </Item>
        </>
      )
    })
  }

  return (
    <>
      <Header className="header clearfix fixed">
        <div className="logo">{WebSite?.name}</div>
        <div className="left-menu" style={{ float: 'left', width: '50%' }}>
          <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px' }}>
            {getHeadersValue(headerMaps)}
          </Menu>
        </div>
        <div className="right-menu" style={{ float: 'right', width: '30%' }}>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ lineHeight: '64px', float: 'right' }}
          >
            <Menu.Item key="right-menu-1">
              {/* <Badge count={25} overflowCount={10} style={{ marginLeft: 10 }}> */}
              <NotificationTwoTone />
              {/* </Badge> */}
            </Menu.Item>
            <SubMenu
              title={
                <Badge dot={false}>
                  <Avatar
                    shape="square"
                    src="https://joeschmoe.io/api/v1/random"
                    icon={<UserOutlined />}
                  />
                </Badge>
              }
            >
              {getSettings(setConf)}

              <Menu.Item key="logout">
                <span onClick={() => {}}>退出登录</span>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Header>
    </>
  )
}

export default MenuHeader
