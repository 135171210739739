import {
  HomeTwoTone,
  QuestionCircleTwoTone,
  RightCircleTwoTone,
  BoxPlotFilled,
} from '@ant-design/icons'
import { lazy } from 'react'
import { IHeaderConf, IRouterConf, ISettingConf } from './interface'

// 站点系统配置
export const WebSite = {
  name: 'Moxixii中台测试站点',
  copyright: (
    <>
      <a href="https://github.com/fwx5618177">Fork me on Github</a>, Mixed by
      wenxuan feng @2022, currently under developing.
    </>
  ),
}

// 左侧菜单
export const maps: IRouterConf[] = [
  {
    name: '首页',
    title: 'home',
    path: '/',
    component: lazy(() => import('../pages/index')),
    iconType: <HomeTwoTone />,
    key: 'home',
    badge: false,
    children: [],
  },
  {
    name: '游戏',
    title: '游戏',
    path: '/games',
    // component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'games',
    badge: false,
    children: [
      {
        name: '马里奥',
        title: '马里奥',
        path: '/games/mario',
        component: lazy(() => import('../pages/games/MarioGames/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'mario',
        badge: false,
        children: [],
      },
    ],
  },
  {
    name: '常见组件',
    title: '组件',
    path: '/commonComp',
    // component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'commonComp',
    badge: false,
    children: [
      {
        name: 'ReactCalendar',
        title: 'ReactCalendar',
        path: '/commonComp/calendar',
        component: lazy(() => import('../pages/commonComp/CalendarReact/index')),
        // component: lazy(() => import('../pages/games/MarioGames/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'calendar',
        badge: false,
        children: [],
      },
      {
        name: 'pdf处理',
        title: 'pdf处理',
        path: '/commonComp/ispdf',
        component: lazy(() => import('../pages/commonComp/PDFReact/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'pdf',
        badge: false,
        children: [],
      },
      {
        name: 'mo动画组件',
        title: 'mo动画组件',
        path: '/commonComp/mojs',
        component: lazy(() => import('../pages/commonComp/MoJS/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'mo',
        badge: false,
        children: [],
      },
      {
        name: 'cobe_webGL',
        title: 'cobe',
        path: '/commonComp/cobe',
        component: lazy(() => import('../pages/commonComp/cobe/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'cobe',
        badge: false,
        children: [],
      },
      {
        name: 'openseadragon',
        title: '图像不失真缩放',
        path: '/commonComp/openseadragon',
        component: lazy(() => import('../pages/commonComp/openseadragon/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'openseadragon',
        badge: false,
        children: [],
      },
      {
        name: 'roughViz',
        title: '手绘图表',
        path: '/commonComp/roughViz',
        component: lazy(() => import('../pages/commonComp/roughViz/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'roughViz',
        badge: false,
        children: [],
      },
      {
        name: 'perspective',
        title: '流数据集展示',
        path: '/commonComp/perspective',
        component: lazy(() => import('../pages/commonComp/perspective/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'perspective',
        badge: false,
        children: [],
      },
      {
        name: 'two',
        title: '二维绘图',
        path: '/commonComp/two',
        component: lazy(() => import('../pages/commonComp/two/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'two',
        badge: false,
        children: [],
      },
      {
        name: 'Neutralinojs',
        title: '桌面应用程序开发框架',
        path: '/commonComp/Neutralinojs',
        component: lazy(() => import('../pages/commonComp/Neutralinojs/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'Neutralinojs',
        badge: false,
        children: [],
      },
      {
        name: 'FramerMotion3D',
        title: 'FramerMotion3D',
        path: '/commonComp/FramerMotion3D',
        component: lazy(() => import('../pages/commonComp/FramerMotion3D/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'FramerMotion3D',
        badge: false,
        children: [],
      },
      {
        name: 'asciinema',
        title: '播放器asciinema',
        path: '/commonComp/asciinema',
        component: lazy(() => import('../pages/commonComp/asciinema/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'asciinema',
        badge: false,
        children: [],
      },
      {
        name: 'three',
        title: '3Dthree',
        path: '/commonComp/three',
        component: lazy(() => import('../pages/commonComp/three/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'three',
        badge: false,
        children: [],
      },
      {
        name: 'nivo',
        title: '图标nivo',
        path: '/commonComp/nivo',
        component: lazy(() => import('../pages/commonComp/nivo/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'nivo',
        badge: false,
        children: [],
      },
      {
        name: 'openlayers',
        title: '地图图标',
        path: '/commonComp/openlayers',
        component: lazy(() => import('../pages/commonComp/openlayers/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'openlayers',
        badge: false,
        children: [],
      },
      {
        name: 'excellentexport',
        title: 'excel',
        path: '/commonComp/excellentexport',
        component: lazy(() => import('../pages/commonComp/excellentexport/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'excellentexport',
        badge: false,
        children: [],
      },
      {
        name: 'ngraph',
        title: 'ngraph',
        path: '/commonComp/ngraph',
        component: lazy(() => import('../pages/commonComp/ngraph/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'ngraph',
        badge: false,
        children: [],
      },
      {
        name: 'SVG',
        title: 'SVG',
        path: '/commonComp/SVG',
        component: lazy(() => import('../pages/commonComp/SVG/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'SVG',
        badge: false,
        children: [],
      },
      // {
      //   name: 'Deri',
      //   title: 'Deri',
      //   path: '/commonComp/Deri',
      //   component: lazy(() => import('../pages/commonComp/Deri/index')),
      //   iconType: <QuestionCircleTwoTone />,
      //   key: 'Deri',
      //   badge: false,
      //   children: [],
      // },
      {
        name: 'XState-chatbot',
        title: 'XState-chatbot',
        path: '/commonComp/xStateChatBot',
        component: lazy(() => import('../pages/commonComp/xStateChatBot/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'XState-chatbot',
        badge: false,
        children: [],
      },
      {
        name: 'shields',
        title: 'shields',
        path: '/commonComp/shields',
        component: lazy(() => import('../pages/commonComp/shields/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'shields',
        badge: false,
        children: [],
      },
      {
        name: 'thinkCharts',
        title: '思维导图或者状态机',
        path: '/commonComp/thinkCharts',
        component: lazy(() => import('../pages/commonComp/thinkCharts/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'thinkCharts',
        badge: false,
        children: [],
      },
      {
        name: 'marked',
        title: 'marked',
        path: '/commonComp/marked',
        component: lazy(() => import('../pages/commonComp/marked/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'marked',
        badge: false,
        children: [],
      },
      {
        name: 'stylo',
        title: 'stylo富文本编辑器',
        path: '/commonComp/stylo',
        component: lazy(() => import('../pages/commonComp/stylo/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'stylo',
        badge: false,
        children: [],
      },
      {
        name: 'xata',
        title: 'xata截图',
        path: '/commonComp/xata',
        component: lazy(() => import('../pages/commonComp/xata/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'xata',
        badge: false,
        children: [],
      },
      {
        name: 'sigma',
        title: 'sigma力图',
        path: '/commonComp/sigma',
        component: lazy(() => import('../pages/commonComp/sigma/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'sigma',
        badge: false,
        children: [],
      },
      {
        name: 'webtoon/psd',
        title: 'webtoon/psd-线上解析psd',
        path: '/commonComp/psd',
        component: lazy(() => import('../pages/commonComp/psd/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'psd',
        badge: false,
        children: [],
      },
      {
        name: 'shader_park_core',
        title: '简化可交互3d图像',
        path: '/commonComp/shader_park_core',
        component: lazy(() => import('../pages/commonComp/shader_park_core/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'shader_park_core',
        badge: false,
        children: [],
      },
      {
        name: 'react_file',
        title: '文件树形展示',
        path: '/commonComp/file_tree',
        component: lazy(() => import('../pages/commonComp/file_tree/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'file_tree',
        badge: false,
        children: [],
      },
      {
        name: 'deck',
        title: '地图3d展示',
        path: '/commonComp/deck',
        component: lazy(() => import('../pages/commonComp/deck/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'deck',
        badge: false,
        children: [],
      },
      {
        name: 'piling',
        title: '图片切片',
        path: '/commonComp/piling',
        component: lazy(() => import('../pages/commonComp/piling/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'piling',
        badge: false,
        children: [],
      },
      {
        name: 'chakra',
        title: '现代UI-Chakra UI',
        path: '/commonComp/chakra',
        component: lazy(() => import('../pages/commonComp/chakra/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'piling',
        badge: false,
        children: [],
      },
    ],
  },
  {
    name: '需求',
    title: '需求',
    path: '/requirements',
    // component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'requirements',
    badge: false,
    children: [
      {
        name: 'd3Move',
        title: 'd3Move(未完成)',
        path: '/requirements/d3Move',
        component: lazy(() => import('../pages/requirements/d3Move/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'd3Move',
        badge: false,
        children: [],
      },
    ],
  },
  {
    name: '图形学',
    title: '图形学',
    path: '/visual',
    // component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'visual',
    badge: false,
    children: [
      {
        name: 'WebGPU',
        title: 'WebGPU',
        path: '/visual/WebGPU',
        component: lazy(() => import('../pages/visual/WebGPU/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'WebGPU',
        badge: false,
        children: [],
      },
      {
        name: '冰墩墩',
        title: 'threejsOlympic',
        path: '/visual/olympic',
        component: lazy(() => import('../pages/visual/olympic/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'olympic',
        badge: false,
        children: [],
      },
      {
        name: 'd3',
        title: 'd3',
        path: '/visual/d3',
        component: lazy(() => import('../pages/visual/d3/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'd3',
        badge: false,
        children: [],
      },
    ],
  },
  {
    name: '富文本',
    title: '富文本',
    path: '/richtext',
    // component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'richtext',
    badge: false,
    children: [
      {
        name: 'lexical',
        title: 'lexical',
        path: '/richtext/lexical',
        component: lazy(() => import('../pages/richtext/lexical/index')),
        iconType: <QuestionCircleTwoTone />,
        key: 'lexical',
        badge: false,
        children: [],
      },
    ],
  },
  {
    name: '过往产品展示',
    title: '过往产品展示',
    path: '/product',
    iconType: <RightCircleTwoTone />,
    key: 'product',
    badge: false,
    children: [
      {
        name: 'City',
        title: '3D城市',
        path: '/product/city',
        component: lazy(() => import('../pages/product/city/index')),
        iconType: <BoxPlotFilled />,
        key: 'city',
        badge: false,
        children: [],
      },
    ],
  },
]

// 头部菜单
export const headerMaps: IHeaderConf[] = [
  {
    name: '首页',
    title: 'home',
    path: '/',
    component: '../pages/index',
    iconType: <HomeTwoTone />,
    key: 'home',
    badge: false,
    children: [],
  },
  {
    name: '游戏',
    title: '游戏',
    path: '/games',
    component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'games',
    badge: false,
    // eslint-disable-next-line no-sparse-arrays
    children: [
      {
        name: '马里奥',
        title: '马里奥',
        path: '/games/mario',
        component: '../pages/games/mario/index.tsx',
        iconType: <QuestionCircleTwoTone />,
        key: 'mario',
        badge: false,
        children: [],
      },
      ,
    ],
  },
]

// 设置菜单
export const setConf: ISettingConf[] = [
  {
    name: '用户中心',
    title: '用户中心',
    path: '/user',
    component: '../pages/index',
    iconType: <RightCircleTwoTone />,
    key: 'user',
    badge: false,
    // eslint-disable-next-line no-sparse-arrays
    children: [
      {
        name: '个人信息',
        title: '个人信息',
        path: '/userInfo',
        component: '../pages/index',
        iconType: <BoxPlotFilled />,
        key: 'userInfo',
        badge: false,
        children: [],
      },
      ,
    ],
  },
]
